import axios from 'utils/axios';

export const getPosts = async (page: number = 0, size: number = 10) => {
  try {
    const { data } = await axios.get(`api/posts?page=${page}&size=${size}`);
    return data;
  } catch (err) {
    console.log(err);
    return { count: 0, rows: [], totalPages: 0 };
  }
};

export const deletePost = async (postId: number) => {
  try {
    await axios.delete(`api/posts/${postId}`);
  } catch (err) {
    console.log(err);
  }
};
