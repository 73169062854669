import React from 'react';
import CCurrencyFormat from 'react-currency-format';
interface CurrencyFormatI {
  value?: number;
  prefix?: string;
  className?: string;
  thousandSeparator?: string,
  decimalSeparator?: string
}
export const CurrencyFormat = ({
  value,
  prefix,
  className,
  thousandSeparator=" ",
  decimalSeparator="."
}: CurrencyFormatI) => (
  <CCurrencyFormat
    value={value}
    displayType={'text'}
    thousandSeparator={thousandSeparator}
    prefix={prefix}
    className={className}
    decimalSeparator={decimalSeparator}
  />
);
