import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'components';
import cn from 'classnames';
import './index.scss';

interface ButtonProps {
  variant?: 'primary' | 'outline';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  className?: string;
  onClick?: (e: any) => void;
  children: React.ReactNode;
  linkTo?: string;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  loading?: boolean;
}

export const Button = ({
  variant = 'primary',
  size = 'md',
  className,
  onClick,
  children,
  linkTo,
  type = 'button',
  disabled,
  loading
}: ButtonProps) => {
  const classes = cn(
    'cb-button',
    `cb-button-${variant}`,
    `cb-button-size-${size}`,
    className,
    {
      'pointer-events-none': disabled
    }
  );

  const Children: any = useMemo(
    () => (loading ? <Icon name="Spinner" /> : children),
    [children, loading]
  );

  return linkTo ? (
    <Link className={classes} to={linkTo}>
      {Children}
    </Link>
  ) : (
    <button className={classes} onClick={onClick} type={type}>
      {Children}
    </button>
  );
};
