import axios from 'axios';
import { logout } from 'api/auth';

export const authIntance = axios.create({
  baseURL: process.env.REACT_APP_AUTH_API_URL
});

authIntance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('tcc-token-ad');
    const lng = localStorage.getItem('i18nextLng') || 'en';
    config.params = { lng };
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

authIntance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error.response.status) {
      logout();
    } else {
      return Promise.reject(error);
    }
  }
);

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

instance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('tcc-token-ad');
    const lng = localStorage.getItem('i18nextLng') || 'en';

    if (token) config.headers.Authorization = `Bearer ${token}`;
    config.params = { lng };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error.response.status) {
      logout();
    } else {
      return Promise.reject(error);
    }
  }
);

export default instance;
