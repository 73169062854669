import cn from 'classnames';

interface DividerProps {
  className?: string;
  color?: string;
}

export const Divider = ({ className, color = 'light' }: DividerProps) => {
  const colors: Record<string, string> = {
    light: '#f2f3f5',
    dark: '#d6d6d6'
  };
  return (
    <hr
      className={cn('cb-divider block w-full bg-gray-light', className)}
      style={{ borderColor: colors[color], height: '0.5px' }}
    />
  );
};
