import { locationToString } from 'utils/formatter';
import ReactCountryFlag from 'react-country-flag';
import cn from 'classnames';

export const PublicationLocation = ({
  publication
}: {
  publication: Publication;
}) => {
  const location = locationToString(publication?.location!);

  return (
    <div className={cn('flex items-center w-max max-w-full')}>
      <ReactCountryFlag
        className="mr-1.5 flex rounded-full object-cover"
        svg
        countryCode={publication?.location?.countryCode || 'US'}
      />
      <span
        className={cn(
          'text-xs color-gray overflow-hidden overflow-ellipsis whitespace-nowrap w-full'
        )}
      >
        {location}
      </span>
    </div>
  );
};
