import React, { useEffect, useState, useCallback } from 'react';
import cn from 'classnames';
import {
  getCompanies,
  verifyCompany,
  deleteCompany,
  toggleVIP
} from 'api/companies';
import { Table, Icon, CompanyBadge, Menu } from 'components';
import { useTranslation } from 'react-i18next';
import { usePageQueryParams } from 'hooks';
import { Spinner } from 'components';
import { MenuType } from 'components/Menu';
import { toast } from 'react-toastify';

const Companies = () => {
  const { t } = useTranslation();
  const [companies, setCompanies] = useState<User[]>([]);
  const [selected, setSelected] = useState<number>(0);
  const { getPage, setPage } = usePageQueryParams();
  const [resultsTotal, setResultsTotal] = useState({
    count: 0,
    totalPages: 0
  });
  const [loading, setLoading] = useState<boolean>(true);
  const page = getPage() || 1;

  const defaultUser =
    'https://the-crane-club.fra1.digitaloceanspaces.com/utils/utils/default-user.jpeg';

  useEffect(() => {
    setLoading(true);
    load();
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const load = async () => {
    try {
      const { count, rows, totalPages } = await getCompanies(page - 1, 12);
      setResultsTotal({ count, totalPages });
      setCompanies(rows);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const verify = async (id: number, isVerified: boolean, index: number) => {
    try {
      setSelected(id);
      await verifyCompany(id, isVerified);
      toast.success(t('notifications.genericSuccess'));
      const updatedCompanies = companies;
      updatedCompanies[index].isVerified = isVerified;
      setCompanies(updatedCompanies);
    } catch (error) {
      console.log(error);
    } finally {
      setSelected(0);
    }
  };

  const handleOnDelete = async (id: number) => {
    try {
      setSelected(id);
      await deleteCompany(id);
      toast.success(t('notifications.companyDeleted'));
      load();
    } catch (error) {
      console.log(error);
      toast.error(t('notifications.genericError'));
    } finally {
      setSelected(0);
    }
  };

  const handleOnVIP = async (id: number, isVIP: boolean, index: number) => {
    try {
      setSelected(id);
      await toggleVIP(id, isVIP);
      const _companies: User[] = companies;
      _companies[index].isVIP = isVIP;
      setCompanies(_companies);
      toast.success(t('notifications.genericSuccess'));
      load();
    } catch (error) {
      console.log(error);
      toast.error(t('notifications.genericError'));
    } finally {
      setSelected(0);
    }
  };

  const renderMenu = useCallback(
    (user: User, index: number) => {
      const menu: MenuType[] = [];
      if (!user.isVerified) {
        menu.push({
          label: t('verify'),
          icon: 'Approve',
          onClick: () => verify(user.uid!, true, index)
        });
      } else
        menu.push({
          label: t('unverify'),
          icon: 'Reject',
          onClick: () => verify(user.uid!, false, index)
        });
      if (!user.isVIP) {
        menu.push({
          label: t('makeVIP'),
          icon: 'Approve',
          onClick: () => handleOnVIP(user.uid!, true, index)
        });
      } else
        menu.push({
          label: t('removeVIP'),
          icon: 'Reject',
          onClick: () => handleOnVIP(user.uid!, false, index)
        });
      menu.push({
        label: t('delete'),
        icon: 'Trash',
        onClick: () => handleOnDelete(user.uid!)
      });
      return menu;
    },
    [t, companies]
  );

  const renderRows = () => {
    return companies.map((user: User, index: number) => (
      <tr
        key={user.uid}
        className="hover:bg-yellow-light transition duration-300"
      >
        <Table.Cell className="cursor-pointer">
          <div className="flex items-center">
            <div className="flex-shrink-0 h-12 w-12 bg-no-repeat bg-contain rounded-full bg-center overflow-hidden border border-solid border-silver-200">
              <img
                src={user?.photoURL || defaultUser}
                onError={(e) => {
                  e.currentTarget.src = defaultUser;
                }}
                alt="Company"
                className="object-contain w-full h-full p-1"
              />
            </div>
            <div className="ml-4 flex items-center justify-center">
              <div className="text-sm font-medium text-gray-900">
                {user.name}
              </div>
              {user.websiteURL && (
                <a
                  href={user.websiteURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ml-2"
                >
                  <Icon name="World" width={15} height={15} />
                </a>
              )}
            </div>
          </div>
        </Table.Cell>
        <Table.Cell>{user.uid}</Table.Cell>
        <Table.Cell>
          <span className="block mb-2">
            {user.companyResponsableName || ''}
          </span>
          <span>{user.companyResponsableRole}</span>
        </Table.Cell>
        <Table.Cell>
          <a href={`mailto:${user.email}`} className="block mb-2">
            {user.email || ''}
          </a>
          <a href={`tel:+${user.phoneNumber}`}>+{user.phoneNumber}</a>
        </Table.Cell>
        <Table.Cell>{user.country}</Table.Cell>
        <Table.Cell>{user.isVIP ? <Icon name="TickSolid" /> : null}</Table.Cell>
        <Table.Cell>
          <CompanyBadge isVerified={user.isVerified} />
        </Table.Cell>
        <Table.Cell>
          <Menu
            trigger={
              <Icon
                name="VerticalDots"
                width="16"
                height="16"
                canvasWidth={34}
                canvasHeight={34}
                rounded
                center
                loading={selected === user.uid}
                onClick={() => null}
              />
            }
            items={renderMenu(user, index)}
          />
        </Table.Cell>
      </tr>
    ));
  };

  return (
    <div
      className={cn('py-5 px-10', {
        'animate-pulse pointer-events-none': loading
      })}
    >
      <h3 className="text-2xl">{t('companies')}</h3>
      <div className="flex items-center justify-between h-8 mb-5">
        <div
          className={cn('text-silver text-xs', {
            'bg-silver-light rounded-sm text-transparent w-24 h-4': loading
          })}
        >
          {!loading && (
            <span className="text-gray">
              {t('totalItems', { value: resultsTotal.count })}
            </span>
          )}
        </div>
      </div>

      <div className={`grid grid-cols-1 gap-6`}>
        {loading ? (
          <Spinner />
        ) : (
          <Table
            page={page}
            setPage={setPage}
            totalPages={resultsTotal.totalPages}
            headerLabels={[
              t('name'),
              t('#Id'),
              t('responsable'),
              `${t('email')} / ${t('phone')}`,
              t('country'),
              t('VIP'),
              t('status'),
              ''
            ]}
            rows={renderRows()}
          ></Table>
        )}
      </div>
    </div>
  );
};

export default Companies;
