import { useTranslation } from 'react-i18next';
import cn from 'classnames';

interface SwitchProps {
  value?: string;
  setValue: (value: any) => void;
  className?: string;
  items: Array<any>;
  error?: any;
}

export const Switch = ({
  value,
  setValue,
  className,
  items,
  error
}: SwitchProps) => {
  const { t } = useTranslation();

  return (
    <div className={cn('h-9 w-max', className)}>
      <div className=" flex h-full row bg-transparent text-sm font-medium tracking-tight">
        {items.map((item, index) => (
          <div
            className={cn(
              'px-5 relative cursor-pointer flex items-center justify-center border-solid border-silver',
              !index
                ? 'rounded-l-md border-t border-l border-b border-r-1/2'
                : index === items.length - 1
                ? 'rounded-r-md border-t border-r border-b'
                : 'border-t border-b border-r-0 border-l-0 border-r-1/2',
              value === (item.text ? item.value : item)
                ? 'text-white bg-yellow'
                : 'text-silver'
            )}
            key={index}
            onClick={() => setValue(item.text ? item.value : item)}
          >
            {item.text || item}
          </div>
        ))}
      </div>
      {error && (
        <span className="text-2xs text-yellow-dark flex justify-end mt-1">
          {t(`form.${error.type}`)}
        </span>
      )}
    </div>
  );
};
