import React from 'react';
import { useTranslation } from 'react-i18next';
import { Map, PublicationLocation } from 'components';

export const PublicationMap = ({
  publication
}: {
  publication: Publication;
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="md:flex items-center mb-5">
        <h4 className="text-xl font-weight-300 mr-6 mb-5 md:mb-0">
          {t('location')}
        </h4>
        <div className="w-min bg-white justify-center rounded-full border border-1/2 border-solid border-yellow h-9 flex items-center px-3">
          <PublicationLocation publication={publication} />
        </div>
      </div>

      <div className="bg-white rounded-lg p-3 md:p-6 h-60 md:h-110">
        <Map
          markers={publication?.location ? [publication?.location] : null}
          className="h-full"
        />
      </div>
    </div>
  );
};
