import React from 'react';
import { Switch } from 'react-router-dom';
import AuthMiddleware from 'routes/middleware/AuthMiddleware';

import { adminRoutes } from 'routes/allRoutes';

import { AdminLayout } from 'layouts';

export const AdminRoutes = () => (
  <AdminLayout>
    <Switch>
      {adminRoutes.map((route, idx) => (
        <AuthMiddleware
          path={route.path}
          component={route.component}
          exact={route.exact || false}
          key={idx}
          isAuthProtected
        />
      ))}
    </Switch>
  </AdminLayout>
);
