import axios from 'utils/axios';

export const getQuotations = async (page: number = 0, size: number = 10) => {
  try {
    const { data } = await axios.get(
      `api/quotations?page=${page}&size=${size}`
    );
    return data;
  } catch (err) {
    console.log(err);
    return { count: 0, rows: [], totalPages: 0 };
  }
};
