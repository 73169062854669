import React from 'react';
import cn from 'classnames';

interface CellProps {
  onClick?: () => void;
  className?: string;
  children?: React.ReactNode;
}

export const Cell = ({ onClick, className, children }: CellProps) => {
  return (
    <th
      scope="col"
      onClick={onClick}
      className={cn(
        className,
        'px-6 py-4 text-left text-xs font-medium text-gray-500 whitespace-nowrap'
      )}
    >
      {children}
    </th>
  );
};
