import { useEffect, useState, useCallback } from 'react';
import cn from 'classnames';
import { getQuotations } from 'api/quotations';
import { useTranslation } from 'react-i18next';
import { usePageQueryParams } from 'hooks';
import { Table, Spinner, Menu, Icon } from 'components';
import { MenuType } from 'components/Menu';
import {
  formatDate,
  publicationToURL,
  profileToURL
} from '../../utils/formatter';

const defaultUser =
  'https://the-crane-club.fra1.digitaloceanspaces.com/utils/utils/default-user.jpeg';

const Quotations = () => {
  const { t } = useTranslation();
  const [quotations, setQuotations] = useState<any>([]);
  const { getPage, setPage } = usePageQueryParams();
  const [resultsTotal, setResultsTotal] = useState({
    count: 0,
    totalPages: 0
  });
  const [loading, setLoading] = useState<boolean>(true);
  const page = getPage() || 1;

  useEffect(() => {
    setLoading(true);
    load();
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const renderMenu = useCallback(
    (publication: Publication): MenuType[] => {
      return [
        {
          label: t('seePublication'),
          icon: 'ShowPassword',
          onClick: () => {
            window.open(
              `${process.env.REACT_APP_CLIENT_URL}${publicationToURL(
                publication
              )}`
            );
          }
        }
      ];
    },
    [t]
  );

  const handleOnProfileClick = (user: User) => {
    window.open(`${process.env.REACT_APP_CLIENT_URL}${profileToURL(user)}`);
  };

  const load = async () => {
    try {
      const { count, rows, totalPages } = await getQuotations(page - 1, 12);
      setResultsTotal({ count, totalPages });
      setQuotations(rows);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const renderRows = () => {
    return quotations.map(
      (quotation: any): JSX.Element => (
        <tr
          key={quotation.id}
          className="hover:bg-orange-light transition duration-300"
        >
          <Table.Cell>{formatDate(quotation.createdAt)}</Table.Cell>
          <Table.Cell className="cursor-pointer" onClick={() => null}>
            <div className="flex items-center">
              <div
                className="flex-shrink-0 h-10 w-12 bg-no-repeat bg-cover rounded-md bg-center"
                style={{
                  backgroundImage: `url('${
                    quotation?.quotationUser?.photoURL || defaultUser
                  }')`
                }}
              />
              <div
                className="ml-4 hover:underline"
                onClick={() => handleOnProfileClick(quotation.quotationUser)}
              >
                <div className="text-sm font-medium text-gray-900 max-w-xl">
                  <p className="text-ellipsis overflow-hidden">
                    {quotation?.quotationUser?.name}
                  </p>
                </div>
              </div>
            </div>
          </Table.Cell>
          <Table.Cell className="cursor-pointer" onClick={() => null}>
            <div className="flex items-center">
              <div
                className="flex-shrink-0 h-10 w-12 bg-no-repeat bg-cover rounded-md bg-center"
                style={{
                  backgroundImage: `url('${
                    quotation?.publication?.user?.photoURL || defaultUser
                  }')`
                }}
              />
              <div
                className="ml-4 hover:underline"
                onClick={() => handleOnProfileClick(quotation.publication.user)}
              >
                <div className="text-sm font-medium text-gray-900 max-w-xl">
                  <p className="text-ellipsis overflow-hidden">
                    {quotation?.publication?.user?.name}
                  </p>
                </div>
              </div>
            </div>
          </Table.Cell>
          <Table.Cell className="cursor-pointer" onClick={() => null}>
            <div className="flex items-center">
              <div
                className="flex-shrink-0 h-10 w-12 bg-no-repeat bg-cover rounded-md bg-center"
                style={{
                  backgroundImage: `url('${quotation.publication?.publicationFiles[0]?.url}')`
                }}
              />
              <div className="ml-4">
                <div className="text-sm font-medium text-gray-900">
                  <span className="block text-xs text-gray-500">
                    {quotation.publication.category?.name}
                  </span>
                  <strong>
                    {quotation.publication?.customBrand ||
                      quotation.publication?.brand?.name}
                  </strong>{' '}
                  {quotation.publication?.model}
                </div>
              </div>
            </div>
          </Table.Cell>
          <Table.Cell>
            <Menu
              trigger={
                <Icon
                  name="VerticalDots"
                  width={16}
                  height="16"
                  canvasWidth={34}
                  canvasHeight={34}
                  rounded
                  center
                  onClick={() => null}
                />
              }
              items={renderMenu(quotation.publication)}
            />
          </Table.Cell>
        </tr>
      )
    );
  };

  return (
    <>
      <div
        className={cn('py-5 px-10', {
          'animate-pulse pointer-events-none': loading
        })}
      >
        <h3 className="text-2xl">{t('quotations')}</h3>
        <div className="flex items-center justify-between h-8 mb-5">
          <div
            className={cn('text-silver text-xs', {
              'bg-silver-light rounded-sm text-transparent w-24 h-4': loading
            })}
          >
            {!loading && (
              <span className="text-gray">
                {t('totalItems', { value: resultsTotal.count })}
              </span>
            )}
          </div>
        </div>

        <div className={`grid grid-cols-1 gap-6`}>
          {loading ? (
            <Spinner />
          ) : (
            <Table
              page={page}
              setPage={setPage}
              totalPages={resultsTotal.totalPages}
              headerLabels={[
                t('created'),
                t('from'),
                t('to'),
                t('publication'),
                ''
              ]}
              rows={renderRows()}
            ></Table>
          )}
        </div>
      </div>
    </>
  );
};

export default Quotations;
