import React, { useEffect } from 'react';
import { getPaginationArray } from 'utils/pagination';
import cn from 'classnames';
import { Icon } from 'components';

interface PaginationProps {
  totalPages: number;
  currentPage: number;
  setPages: (page: number) => void;
  className?: string;
}

export const Pagination = ({
  totalPages,
  currentPage,
  setPages,
  className
}: PaginationProps) => {
  const pages = getPaginationArray(currentPage, totalPages, 3);

  useEffect(() => {}, []);

  const handleOnNumberClick = (page: string) => {
    if (page !== '...') setPages(parseInt(page));
  };

  return (
    <div
      className={cn(
        'mt-4 px-4 py-3 flex items-center justify-center',
        className
      )}
    >
      <div>
        <div
          className="relative z-0 inline-flex rounded-md -space-x-px"
          aria-label="Pagination"
        >
          {currentPage !== 1 && (
            <div
              onClick={() => setPages(currentPage - 1)}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md text-sm font-medium hover:bg-gray-50 cursor-pointer"
            >
              <Icon name="ChevronLeft" />
            </div>
          )}
          {pages.map((page, index) => (
            <div
              key={index}
              onClick={() => handleOnNumberClick(page.toString())}
              className={cn(
                'md:inline-flex relative items-center px-4 py-2 text-sm font-medium',
                {
                  'text-yellow': currentPage === page,
                  'hover:bg-gray-50 cursor-pointer': page !== '...'
                }
              )}
            >
              {page}
            </div>
          ))}
          {currentPage !== totalPages && totalPages > 1 && (
            <div
              onClick={() => setPages(currentPage + 1)}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md text-sm font-medium hover:bg-gray-50 cursor-pointer"
            >
              <Icon name="ChevronRight" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
