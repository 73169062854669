import { useEffect, useState, useCallback } from 'react';
import cn from 'classnames';
import { getPublications, reviewPublication } from 'api/publications';
import { Table, Icon, PublicationBadge, Menu, Switch } from 'components';
import { useTranslation } from 'react-i18next';
import { usePageQueryParams, useModal } from 'hooks';
import defaultMachine from 'assets/img/default-machine.jpg';
import { Spinner, Modal, PublicationToReview } from 'components';
import { MenuType } from 'components/Menu';
import useStateRef from 'react-usestateref';
import { toast } from 'react-toastify';
import Tooltip from '@mui/material/Tooltip';
import './index.scss';

const Publications = () => {
  const { t } = useTranslation();
  const [, setRejectReason, rejectReasonRef] = useStateRef('');
  const [publications, setPublications] = useState<Array<Publication>>([]);
  const [publicationInReview, setPublicationInReview] = useState(-1);
  const [selectedPublication, setSelectedPublication] = useState<number>(-1);
  const [selected, setSelected] = useState('unverified');
  const { getPage, setPage, removePage } = usePageQueryParams();
  const [resultsTotal, setResultsTotal] = useState({
    count: 0,
    totalPages: 0
  });
  const [statusLoading, setStatusLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const page = getPage() || 1;

  const {
    toggleModal,
    visible,
    modalTitle,
    modalButtons,
    modalChildren,
    setModalTitle,
    setModalButtons,
    setModalChildren
  } = useModal();

  useEffect(() => {
    if (!loading) {
      loadPublications();
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, selected]);

  const openRejectModal = useCallback(
    (id) => {
      setModalTitle(t('rejectPublicationModalTitle'));
      setModalChildren(
        <textarea
          className="border border-silver border-solid resize-none rounded-lg w-105 h-44 text-sm text-gray"
          onChange={(e) => {
            setRejectReason(e.target.value);
          }}
        ></textarea>
      );
      setModalButtons([
        {
          text: t('send'),
          onClick: async () => await reject(id),
          fullWidth: true
        }
      ]);
      toggleModal();
    },
    [setModalTitle, t, setModalButtons, toggleModal]
  );

  const loadPublications = async () => {
    try {
      setLoading(true);
      const { count, rows, totalPages } = await getPublications(
        page - 1,
        12,
        selected
      );
      setPublications(rows);
      setResultsTotal({ count, totalPages });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const approve = useCallback(
    async (id: number) => {
      try {
        setStatusLoading(true);
        setSelectedPublication(id);
        await reviewPublication({
          publicationId: id,
          isVerified: true,
          comments: ''
        });
        toast.success(t('approveSuccess'));
        refreshPage();
      } catch (err) {
        toast.error(t('approveError'));
        console.log(err);
      } finally {
        setStatusLoading(false);
      }
    },
    [selected]
  );

  const reject = async (id: number) => {
    try {
      setStatusLoading(true);
      setSelectedPublication(id);
      await reviewPublication({
        publicationId: id,
        isVerified: false,
        comments: rejectReasonRef.current
      });
      toast.success(t('rejectSuccess'));
      refreshPage();
    } catch (err) {
      toast.error(t('rejectError'));
    } finally {
      setStatusLoading(false);
    }
  };

  const handleOnSelected = (state: string) => {
    setPage(1);
    setSelected(state);
  };

  const refreshPage = () => {
    setPublicationInReview(-1);
    setSelectedPublication(-1);
    removePage();
    loadPublications();
  };

  const renderRows = () => {
    return publications.map(
      (publication: Publication, index: number): JSX.Element => (
        <tr
          key={index}
          className="hover:bg-yellow-light transition duration-300"
        >
          <Table.Cell className="cursor-pointer" onClick={() => null}>
            <div className="flex items-center">
              <div
                className="flex-shrink-0 h-10 w-12 bg-no-repeat bg-cover rounded-md bg-center"
                style={{
                  backgroundImage: `url('${
                    !!publication?.publicationFiles
                      ? publication?.publicationFiles[0]?.url || defaultMachine
                      : defaultMachine
                  }')`
                }}
              />
              <div className="ml-4">
                <div className="text-sm font-medium text-gray-900">
                  <span className="block text-xs text-gray-500">
                    {`${publication?.type?.name} - ${publication.category?.name}`}
                  </span>
                  <strong>
                    {publication?.customBrand || publication?.brand?.name}
                  </strong>{' '}
                  {publication?.model}
                </div>
              </div>
            </div>
          </Table.Cell>
          <Table.Cell>{publication.id}</Table.Cell>
          <Table.Cell>{publication?.user?.name}</Table.Cell>
          <Table.Cell className="py-0">
            <div className="flex lg:block flex-wrap">
              {(!!publication.EXWPrice || publication.EXWPrice === 0) && (
                <div className="h-6 pt-1 w-min chip sell">{t('sell')}</div>
              )}
              <div className="flex row">
                {(publication?.nationalRentPrice ||
                  publication?.nationalRentPrice) === 0 && (
                  <Tooltip
                    title={t('withOperator') || ''}
                    placement="right"
                    disableHoverListener={!publication?.nationalRentOperator}
                  >
                    <div className="chip rent h-6 w-min pt-1 sm:mt-1 mr-3">
                      {t('nationalRentAbbrevation')}

                      {publication.nationalRentOperator && (
                        <Icon
                          name="Operator"
                          className="bg-blue"
                          width={10}
                          height={11}
                          canvasWidth={19}
                          canvasHeight={19}
                          rounded
                          center
                        />
                      )}
                    </div>
                  </Tooltip>
                )}
                {(publication?.internationalRentPrice ||
                  publication?.internationalRentPrice === 0) && (
                  <Tooltip
                    title={t('withOperator') || ''}
                    placement="right"
                    disableHoverListener={
                      !publication?.internationalRentOperator
                    }
                  >
                    <div className="chip rent h-6 w-min pt-1 sm:mt-1">
                      {t('internationalRentAbbrevation')}
                      {publication?.internationalRentOperator && (
                        <Icon
                          name="Operator"
                          className="bg-blue"
                          width={10}
                          height={11}
                          canvasWidth={19}
                          canvasHeight={19}
                          rounded
                          center
                        />
                      )}
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
          </Table.Cell>
          <Table.Cell>
            <PublicationBadge
              isActive={publication?.isActive}
              isVerified={publication?.isVerified}
              verifiedDate={publication?.verifiedDate}
            />
          </Table.Cell>
          <Table.Cell>
            <Menu
              trigger={
                <Icon
                  name="VerticalDots"
                  width={16}
                  height="16"
                  canvasWidth={34}
                  canvasHeight={34}
                  rounded
                  loading={
                    statusLoading && selectedPublication === publication?.id
                  }
                  center
                  onClick={() => null}
                />
              }
              items={renderMenu(publication)}
            />
          </Table.Cell>
        </tr>
      )
    );
  };

  const renderMenu = useCallback(
    (publication: Publication) => {
      const menu: MenuType[] = [];
      menu.push({
        label: t('seePublication'),
        icon: 'ShowPassword',
        onClick: () => setPublicationInReview(publication.id!)
      });
      if (
        !publication.isVerified ||
        (publication.verifiedDate && !publication.isVerified)
      ) {
        menu.push({
          label: t('approve'),
          icon: 'Approve',
          onClick: () => approve(publication.id!)
        });
      }
      if (
        (!publication.verifiedDate && !publication.isVerified) ||
        (publication.verifiedDate && publication.isVerified)
      ) {
        menu.push({
          label: t('refuse'),
          icon: 'Reject',
          onClick: () => openRejectModal(publication.id)
        });
      }

      return menu;
    },
    [t, openRejectModal, approve, setPublicationInReview]
  );

  return (
    <>
      {publicationInReview !== -1 && (
        <div className="fixed top-0 left-0 w-full h-screen bg-gray-50 z-40 overflow-auto max-h-screen">
          <PublicationToReview
            id={publicationInReview}
            onClose={() => setPublicationInReview(-1)}
            onApprove={() => approve(publicationInReview)}
            onReject={() => openRejectModal(publicationInReview)}
          />
        </div>
      )}

      <div
        className={cn('py-5 px-10', {
          'animate-pulse pointer-events-none': loading
        })}
      >
        <h3 className="text-2xl">{t('machines')}</h3>
        <div className="flex items-center justify-between h-8 mb-5">
          <div
            className={cn('text-silver text-xs', {
              'bg-silver-light rounded-sm text-transparent w-24 h-4': loading
            })}
          >
            {!loading && (
              <span className="text-gray">
                {t('totalItems', { value: resultsTotal.count })}
              </span>
            )}
          </div>
        </div>

        <div className={`grid grid-cols-1`}>
          {loading ? (
            <Spinner />
          ) : (
            <>
              <Switch
                className="flex"
                items={[
                  { value: 'unverified', text: t('unverified') },
                  { value: 'approved', text: t('approved') },
                  { value: 'rejected', text: t('rejected') },
                  { value: 'all', text: t('all') }
                ]}
                value={selected}
                setValue={handleOnSelected}
              />
              <Table
                page={page}
                setPage={setPage}
                totalPages={resultsTotal.totalPages}
                headerLabels={[
                  t('publication'),
                  t('#Id'),
                  t('seller'),
                  t('operationType'),
                  t('state'),
                  ''
                ]}
                rows={renderRows()}
              />
            </>
          )}
        </div>
      </div>
      <Modal
        toggle={toggleModal}
        visible={visible}
        title={modalTitle}
        buttons={modalButtons}
      >
        {modalChildren}
      </Modal>
    </>
  );
};

export default Publications;
