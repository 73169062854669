import { useCallback, useEffect, useState } from 'react';
import { FeaturedData, Box, DoughnutChart } from 'components';
import { getDashboardData } from 'api/dashboard';
import format from 'date-fns/format';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import ReactCountryFlag from 'react-country-flag';

const Home = () => {
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);

  const defaultUser =
    'https://the-crane-club.fra1.digitaloceanspaces.com/utils/utils/default-user.jpeg';

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getDashboardData();
      setData(data);
    } catch (error) {
      toast.error('There was an error loading the data. Please try again');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  const history = useHistory();
  const { t } = useTranslation();

  return (
    <>
      <div className="grid grid-cols-2 gap-4 mb-4 xl:mb-8 xl:grid-cols-4 xl:gap-8">
        <FeaturedData
          icon="Bag"
          iconWidth={27}
          iconHeight={19}
          number={data?.countLastMonthQuotations}
          text={t('receivedQuotations')}
          className="p-5"
          loading={loading}
        />

        <FeaturedData
          icon="Machine"
          iconWidth={25}
          iconHeight={19}
          number={data?.countActivesPublications}
          text={t('activePublications')}
          className="p-5"
          loading={loading}
        />

        <FeaturedData
          icon="Company"
          iconWidth={26}
          iconHeight={18}
          number={data?.countCompany}
          text={t('companies')}
          className="p-5"
          loading={loading}
        />

        <FeaturedData
          icon="User"
          iconWidth={25}
          iconHeight={18}
          number={data?.countUsers}
          text={t('users')}
          className="p-5"
          loading={loading}
        />
      </div>

      <div className="grid grid-cols-1 gap-4 mb-4 xl:grid-cols-5 xl:gap-8 xl:mb-8">
        <Box
          title={t('pendingValidationPublications', {
            value: data?.publicationsUnverified?.count
          })}
          buttonText={t('viewAllF')}
          onClick={() => history.push('/publications')}
          className="xl:col-span-3 bg-white px-9 py-6 rounded-md"
          loading={loading}
        >
          {data?.publicationsUnverified?.rows.length === 0 ? (
            <div className="flex justify-center items-center">
              <p className="text-l">{t('noCompaniesToValidate')}</p>
            </div>
          ) : (
            data?.publicationsUnverified?.rows?.map(
              (publication: any, i: number) => (
                <div
                  key={i}
                  className="py-4 flex items-start border-solid border-b border-gray-light last:border-0 "
                >
                  <div
                    className="flex-shrink-0 w-10 h-10 rounded-md bg-contain bg-no-repeat bg-center shadow-lg mr-3.5"
                    style={{
                      background: `url(${publication.publicationFiles[0]?.url})`
                    }}
                  ></div>
                  <div className="grid grid-cols-3 content-center items-center gap-x-9 w-full">
                    <div className="text-sm font-medium text-gray-900">
                      <span className="block text-xs text-gray-500">
                        {publication.category?.name}
                      </span>
                      <strong>
                        {publication?.customBrand || publication?.brand?.name}
                      </strong>{' '}
                      {publication?.model}
                      <ReactCountryFlag
                        className="ml-2 inline-flex"
                        countryCode={publication?.location?.countryCode || 'US'}
                        svg
                      />
                    </div>
                    <span className="text-gray text-xs">
                      {publication?.user.name}
                    </span>
                    <div className="text-xs text-gray uppercase font-semibold">
                      {format(new Date(publication.updatedAt), 'dd/MM/yy')}
                    </div>
                  </div>
                </div>
              )
            )
          )}
        </Box>
        <div className="box xl:col-span-2 bg-white px-9 py-6 rounded-md">
          <Box
            title={t('publications')}
            className="xl:col-span-3 hidden xl:block"
            loading={loading}
          >
            <div className="flex items-center justify-center">
              <DoughnutChart data={data?.publicationStatus} />
            </div>
          </Box>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 mb-4 xl:grid-cols-5 xl:gap-8 xl:mb-8">
        <Box
          title={t('pendingValidationCompanies', {
            value: data?.companiesUnverified?.count
          })}
          buttonText={t('viewAllCompanies')}
          onClick={() => history.push('/companies')}
          className="xl:col-span-3 bg-white px-9 py-6 rounded-md"
          loading={loading}
        >
          {data?.companiesUnverified?.rows.length === 0 ? (
            <div className="flex justify-center items-center">
              <p className="text-l">{t('noCompaniesToValidate')}</p>
            </div>
          ) : (
            data?.companiesUnverified?.rows?.map((user: any, i: number) => (
              <div
                key={i}
                className="py-4 flex items-center border-solid border-b border-gray-light last:border-0 "
              >
                <div className="flex-shrink-0 h-12 w-12 bg-no-repeat bg-contain rounded-full bg-center overflow-hidden border border-solid border-silver-200">
                  <img
                    src={user?.photoURL || defaultUser}
                    onError={(e) => {
                      e.currentTarget.src = defaultUser;
                    }}
                    alt="Company"
                    className="object-contain w-full h-full p-1"
                  />
                </div>
                <div className="grid grid-cols-3 content-center items-center gap-x-9 w-full ml-4">
                  <div className="flex align-center">
                    <div className="text-sm font-medium text-gray-900">
                      {user?.name ? user?.name : user?.email}
                    </div>
                    {user.location?.countryCode && (
                      <ReactCountryFlag
                        className="ml-2 pt-1"
                        countryCode={user.location?.countryCode}
                        svg
                      />
                    )}
                  </div>
                  <span className="text-gray text-xs">{user?.email || ''}</span>
                  <div className="text-xs text-gray ml-4">
                    {user?.phoneNumber}
                  </div>
                </div>
              </div>
            ))
          )}
        </Box>
      </div>
    </>
  );
};

export default Home;
