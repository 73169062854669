import React from 'react';
import { string, func } from 'prop-types';

import big from 'assets/img/logo/logo-big.svg';
import small from 'assets/img/logo/logo-small.svg';
import collapsed from 'assets/img/logo/logo-collapsed.svg';

const logos = { big, small, collapsed };

interface LogoProps {
  size?: 'big' | 'collapsed' | 'small';
  className?: string;
  onClick?: () => void;
}

export const Logo = ({ size = 'big', className, onClick }: LogoProps) => (
  <div className={className} onClick={onClick}>
    <img src={logos[size]} alt="Logo The Crane Club" />
  </div>
);

Logo.propTypes = {
  size: string,
  className: string,
  onClick: func
};
