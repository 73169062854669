import axios, { authIntance } from 'utils/axios';

export const getMe = async () => {
  try {
    const token = localStorage.getItem('tcc-token-ad');
    if (token) {
      const { data } = await authIntance.get('api/auth/users/me');
      return data;
    } else {
      return null;
    }
  } catch (error: any) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};

export const getUsers = async (page: number = 0, size: number = 10) => {
  try {
    const token = localStorage.getItem('tcc-token-ad');
    if (token) {
      const { data } = await axios.get(`api/users?page=${page}&size=${size}`);
      return data;
    } else {
      return null;
    }
  } catch (error: any) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};

export const toggleInfluencer = async (
  userId: number,
  isInfluencer: boolean
) => {
  try {
    await axios.put(`api/users/toggle-influencer/${userId}`, {
      isInfluencer
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteUser = async (userId: number) => {
  try {
    await axios.delete(`api/users/${userId}`);
  } catch (err) {
    console.log(err);
  }
};
