import React from 'react';
import cn from 'classnames';

import { ReactComponent as Bag } from 'assets/img/icons/bag.svg';
import { ReactComponent as Instagram } from 'assets/img/icons/instagram.svg';
import { ReactComponent as Facebookyellow } from 'assets/img/icons/facebook.svg';
import { ReactComponent as Linkedin } from 'assets/img/icons/linkedin.svg';
import { ReactComponent as Eye } from 'assets/img/icons/eye.svg';
import { ReactComponent as Location } from 'assets/img/icons/location.svg';
import { ReactComponent as Twitter } from 'assets/img/icons/twitter.svg';
import { ReactComponent as Factory } from 'assets/img/icons/factory.svg';
import { ReactComponent as US } from 'assets/img/icons/US.svg';
import { ReactComponent as Spain } from 'assets/img/icons/spain.svg';
import { ReactComponent as Company } from 'assets/img/icons/company.svg';
import { ReactComponent as Trash } from 'assets/img/icons/trash.svg';
import { ReactComponent as Heart } from 'assets/img/icons/heart.svg';
import { ReactComponent as Market } from 'assets/img/icons/market.svg';
import { ReactComponent as HeartSolid } from 'assets/img/icons/heart-solid.svg';
import { ReactComponent as Plus } from 'assets/img/icons/plus.svg';
import { ReactComponent as Warning } from 'assets/img/icons/warning.svg';
import { ReactComponent as Video } from 'assets/img/icons/video.svg';
import { ReactComponent as Image } from 'assets/img/icons/image.svg';
import { ReactComponent as Bookmark } from 'assets/img/icons/bookmark.svg';
import { ReactComponent as BookmarkSolid } from 'assets/img/icons/bookmark-solid.svg';
import { ReactComponent as House } from 'assets/img/icons/house.svg';
import { ReactComponent as Machine } from 'assets/img/icons/machine.svg';
import { ReactComponent as Spinner } from 'assets/img/icons/spinner.svg';
import { ReactComponent as User } from 'assets/img/icons/user.svg';
import { ReactComponent as Bell } from 'assets/img/icons/bell.svg';
import { ReactComponent as Dialog } from 'assets/img/icons/dialog.svg';
import { ReactComponent as Logout } from 'assets/img/icons/logout.svg';
import { ReactComponent as Target } from 'assets/img/icons/target.svg';
import { ReactComponent as Search } from 'assets/img/icons/search.svg';
import { ReactComponent as ChevronRight } from 'assets/img/icons/chevron-right.svg';
import { ReactComponent as ChevronLeft } from 'assets/img/icons/chevron-left.svg';
import { ReactComponent as ChevronDown } from 'assets/img/icons/chevron-down.svg';
import { ReactComponent as ChevronRightCircle } from 'assets/img/icons/chevron-right-circle.svg';
import { ReactComponent as ShowPassword } from 'assets/img/icons/show-password.svg';
import { ReactComponent as HidePassword } from 'assets/img/icons/hide-password.svg';
import { ReactComponent as Facebook } from 'assets/img/icons/social/facebook.svg';
import { ReactComponent as Google } from 'assets/img/icons/social/google.svg';
import { ReactComponent as Apple } from 'assets/img/icons/social/apple.svg';
import { ReactComponent as LinkedIn } from 'assets/img/icons/social/linkedin.svg';
import { ReactComponent as Share } from 'assets/img/icons/share.svg';
import { ReactComponent as List } from 'assets/img/icons/list.svg';
import { ReactComponent as Grid } from 'assets/img/icons/grid.svg';
import { ReactComponent as Play } from 'assets/img/icons/play.svg';
import { ReactComponent as ArrowRightCircle } from 'assets/img/icons/arrow-right-circle.svg';
import { ReactComponent as Certificate } from 'assets/img/icons/certificate.svg';
import { ReactComponent as CertificateYellow } from 'assets/img/icons/certificate-orange.svg';
import { ReactComponent as VerifiedBadget } from 'assets/img/icons/verified-badget.svg';
import { ReactComponent as Email } from 'assets/img/icons/email.svg';
import { ReactComponent as Smartphone } from 'assets/img/icons/smartphone.svg';
import { ReactComponent as Close } from 'assets/img/icons/close.svg';
import { ReactComponent as VerticalDots } from 'assets/img/icons/vertical-dots.svg';
import { ReactComponent as TickSolid } from 'assets/img/icons/tick-solid.svg';
import { ReactComponent as Stop } from 'assets/img/icons/stop.svg';
import { ReactComponent as Tick } from 'assets/img/icons/tick.svg';
import { ReactComponent as Approve } from 'assets/img/icons/approve.svg';
import { ReactComponent as Reject } from 'assets/img/icons/reject.svg';
import { ReactComponent as Operator } from 'assets/img/icons/operator.svg';
import { ReactComponent as SparePart } from 'assets/img/icons/spare-part.svg';
import { ReactComponent as Accessory } from 'assets/img/icons/accesory.svg';
import { ReactComponent as World } from 'assets/img/icons/world.svg';
import { ReactComponent as FullScreen } from 'assets/img/icons/fullscreen.svg';
import { ReactComponent as Posts } from 'assets/img/icons/posts.svg';
import { ReactComponent as Pen } from 'assets/img/icons/pen.svg';
import './index.scss';

export const SvgIcons = {
  Bag,
  Bookmark,
  House,
  Machine,
  User,
  Bell,
  Dialog,
  Logout,
  Target,
  Search,
  ChevronLeft,
  ChevronDown,
  ChevronRight,
  ChevronRightCircle,
  ShowPassword,
  HidePassword,
  Facebook,
  Google,
  FullScreen,
  Apple,
  LinkedIn,
  Video,
  Image,
  Trash,
  Heart,
  Plus,
  List,
  Grid,
  Warning,
  Spinner,
  Instagram,
  Facebookyellow,
  Linkedin,
  Twitter,
  Location,
  US,
  Spain,
  Play,
  ArrowRightCircle,
  Company,
  Share,
  Certificate,
  CertificateYellow,
  VerifiedBadget,
  Email,
  Smartphone,
  Close,
  HeartSolid,
  BookmarkSolid,
  Eye,
  Market,
  Factory,
  VerticalDots,
  TickSolid,
  Stop,
  Tick,
  Approve,
  Reject,
  Operator,
  SparePart,
  Accessory,
  World,
  Posts,
  Pen
};

interface IconProps extends React.SVGAttributes<HTMLOrSVGElement> {
  name: keyof typeof SvgIcons;
  className?: string;
  width?: string | number;
  height?: string | number;
  canvasWidth?: string | number;
  canvasHeight?: string | number;
  onClick?: () => void;
  rounded?: boolean;
  center?: boolean;
  loading?: boolean;
  disabled?: boolean;
}

export const Icon = ({
  name,
  className,
  width = 16,
  height = 16,
  canvasWidth,
  canvasHeight,
  onClick,
  rounded,
  center,
  loading,
  ...rest
}: IconProps) => {
  const SvgIcon = SvgIcons[name];

  const classes = cn('cb-icon', `cb-icon-${name.toLowerCase()}`, className, {
    'cursor-pointer': onClick,
    'flex justify-center items-center': center,
    'rounded-full shadow-md p-2 bg-white': rounded
  });
  return (
    <div
      style={{ width: canvasWidth, height: canvasHeight }}
      className={classes}
      onClick={onClick}
    >
      {loading ? (
        <div className="text-yellow">
          <Spinner
            width={width}
            height={height}
            aria-hidden="true"
            role="img"
            focusable={false}
            {...rest}
          />
        </div>
      ) : (
        <SvgIcon
          width={width}
          height={height}
          aria-hidden="true"
          role="img"
          focusable={false}
          {...rest}
        />
      )}
    </div>
  );
};
