import React from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';

export const PublicationDescription = ({
  description
}: {
  description: string;
}) => {
  const { t } = useTranslation();
  return (
    <div className="py-8">
      <h4 className="text-xl mb-5 font-weight-300">
        {t('publicationDescription')}
      </h4>
      <div
        className="text-xs leading-5 md:text-base md:leading-7 text-description"
        dangerouslySetInnerHTML={{ __html: description }}
      ></div>
    </div>
  );
};
