import { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';

interface Data {
  actives: string[];
  pending: string[];
  rejected: string[];
}

interface DoughnutChartProps {
  data: Data;
}

const getColor = (label: string): string => {
  switch (label) {
    case 'actives':
      return '#04AF00';
    case 'pending':
      return '#F2C94C';
    default:
      return '#E23317';
  }
};

export const DoughnutChart = ({ data }: DoughnutChartProps) => {
  const [labels, setLabels] = useState<string[]>([]);
  const [values, setValues] = useState<number[]>([]);
  const [colors, setColors] = useState<string[]>([]);

  useEffect(() => {
    if (data) {
      const labels: string[] = [],
        colors: string[] = [],
        values: number[] = [];
      Object.entries(data).forEach((e) => {
        labels.push(e[0]);
        values.push(parseInt(e[1] as string));
        colors.push(getColor(e[0]));
      });
      setLabels(labels);
      setValues(values);
      setColors(colors);
    }
  }, []);

  const options = {
    labels,
    datasets: [
      {
        data: values,
        backgroundColor: colors,
        hoverOffset: 4
      }
    ],
    tension: 0.4,
    borderWidth: 1,
    pointBorderWidth: 0
  };
  return <Doughnut data={options} />;
};
