import { format } from 'date-fns';
const { REACT_APP_CLIENT_URL } = process.env;

export const formatter = new Intl.NumberFormat('en', {
  style: 'currency',
  currency: 'USD'
});

export const getPublicationStatus = (
  isActive: boolean = false,
  isVerified: boolean = false,
  verifiedDate?: Date
): string => {
  if (isActive && isVerified) return 'active';
  if (!isActive && isVerified) return 'inactive';
  if (!!verifiedDate && !isVerified) return 'rejected';
  return 'review';
};

interface Location {
  city: string;
  state: string;
  country: string;
}

export const locationToString = (location: Location) =>
  `${location?.city}, ${location?.state}, ${location?.country}`;

export const slugify = (str?: string) => {
  if (!str) return null;

  return str
    .replace(/[:,.]/g, '')
    .replace(/[àáâãäå]/g, 'a')
    .replace(/[èéêë]/g, 'e')
    .replace(/[ìíîï]/g, 'i')
    .replace(/ñ/g, 'n')
    .replace(/[òóôõö]/g, 'o')
    .replace(/[ùúûü]/g, 'u')
    .replace(/[ç]/g, 'c')
    .replace(/\s/g, '-')
    .replace(/[^a-zA-Z0-9-_]/g, '')
    .toLowerCase();
};

export const companyWebsite = (url: string) => {
  if (!url) return null;

  return url.replace('http://', '').replace('https://', '').replace('www.', '');
};

export const publicationToURL = (publication: Publication) =>
  `/p/${slugify(publication?.type?.name)}/${slugify(
    publication?.category?.name
  )}/${slugify(publication?.customBrand || publication?.brand?.name)}/${
    publication?.id
  }/${slugify(publication?.model)}`;

export const profileToURL = (user: User) => {
  return `/u/${user?.uid}/${slugify(user?.name)}`;
};

export const fromTonsToUSTons = (value: number): string => {
  return (value * 1.1023).toFixed(0);
};

export const fromUSTonsToTons = (value: number): string => {
  return (value / 1.1023).toFixed(0);
};

export const fromMeterToFeet = (value: number): string => {
  return (value * 3.28084).toFixed(0);
};

export const fromFeetToMeter = (value: number): string => {
  return (value / 3.28084).toFixed(0);
};

export const formatDate = (
  date: Date,
  options: {
    withTime?: boolean;
  } = {}
) => {
  let formatString = 'd MMM yy';
  if (options.withTime) formatString += ' HH:mm';
  return format(new Date(date), formatString);
};

export const postToURL = (id: number) => `${REACT_APP_CLIENT_URL}/post/${id}`;
