import React from 'react';
import { Sidebar, Navbar } from 'components';

export interface AdminLayoutProps {
  children?: React.ReactNode;
}

export const AdminLayout: React.FC = ({ children }: AdminLayoutProps) => {
  return (
    <>
      <Navbar />
      <Sidebar />
      <main className="pt-23 pl-64 min-h-screen">
        <div className="wrapper-sidebar">{children}</div>
      </main>
    </>
  );
};
