import React from 'react';
import { Pagination } from 'components';
import { useTranslation } from 'react-i18next';
import { Cell } from './Cell';

interface TableProps {
  headerLabels?: string[];
  rows?: any;
  totalPages: number;
  page: number;
  setPage: (x: number) => void;
}

export const Table = ({
  headerLabels,
  rows,
  totalPages,
  page,
  setPage
}: TableProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col">
      <div className="sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full">
          <div className="sm:rounded-lg">
            <table className="min-w-full divide-y table-auto">
              {headerLabels && (
                <thead className="bg-gray-50">
                  <tr>
                    {headerLabels.map((label: string, index: number) => (
                      <th
                        key={index}
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-semibold text-gray-black tracking-tight"
                      >
                        {label}
                      </th>
                    ))}
                  </tr>
                </thead>
              )}
              <tbody className="bg-white divide-y divide-gray-200">
                {rows?.length ? (
                  rows
                ) : (
                  <tr className="bg-white">
                    <td colSpan={9999999}>
                      <p className="text-center py-10">{t('no-data')}</p>
                    </td>
                  </tr>
                )}
              </tbody>
              {!!totalPages && (
                <tfoot>
                  <tr className="bg-white">
                    <td colSpan={9999999}>
                      <Pagination
                        totalPages={totalPages}
                        currentPage={page}
                        setPages={setPage}
                      />
                    </td>
                  </tr>
                </tfoot>
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

Table.Cell = Cell;
