import { useHistory } from 'react-router-dom';
import defaultUser from 'assets/img/default-user.jpeg';
import cn from 'classnames';
import './index.scss';

interface AvaterProps {
  className?: string;
  linkTo: string;
  reverse?: boolean;
  onlyPhoto?: boolean;
  user: User;
  size?: string;
}

export const Avatar = ({
  className,
  linkTo,
  reverse,
  onlyPhoto,
  user,
  size
}: AvaterProps) => {
  const history = useHistory();
  const classes = cn('cb-avatar', className, {
    'flex-row-reverse': reverse,
    'only-photo': onlyPhoto,
    'cursor-pointer': linkTo
  });

  const handleClick = () => history.push(linkTo);

  const defultUser =
    'https://the-crane-club.fra1.digitaloceanspaces.com/utils/utils/default-user.jpeg';

  return user ? (
    <div onClick={handleClick} className={classes}>
      <img
        src={user.photoURL || defultUser}
        alt={''}
        style={{
          ...(size && { height: `${size}px`, width: `${size}px` })
        }}
        className={cn('object-cover')}
        onError={(e) => {
          e.currentTarget.src = defaultUser;
        }}
      />
      {user.name && <p>{user.name}</p>}
    </div>
  ) : null;
};
