import React, { useState } from 'react';
import { ButtonI } from 'components/Modal';

export const useModal = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>('');
  const [modalButtons, setModalButtons] = useState<ButtonI[]>([]);
  const [modalChildren, setModalChildren] = useState<React.ReactNode>(null);

  const toggleModal = () => {
    toggleBodyOverflow();
    setVisible(!visible);
  };

  const toggleBodyOverflow = () => {
    document.body.style.overflow = visible ? 'visible' : 'hidden';
  };

  return {
    toggleModal,
    visible,
    modalTitle,
    setModalTitle,
    modalButtons,
    setModalButtons,
    modalChildren,
    setModalChildren
  };
};
