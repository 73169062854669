import { useContext } from 'react';
import { Loading } from 'components';
import { Route, Redirect } from 'react-router-dom';
import { authRoutes } from '../allRoutes';

import { UserContext } from 'contexts/UserContext';

const AUTH_ROUTES = authRoutes.map((r) => r.path);

interface AuthmiddlewareProps {
  isAuthProtected?: boolean;
  component: any;
  exact?: boolean;
  path: string;
}

const Authmiddleware = ({
  component: Component,
  isAuthProtected = false,
  path,
  exact = false,
  ...rest
}: AuthmiddlewareProps) => {
  const { user } = useContext(UserContext);

  return (
    <Route
      exact={exact}
      {...rest}
      render={(props) => {
        if (typeof user === 'undefined') return <Loading />;
        else if (isAuthProtected && !user) {
          return (
            <Redirect
              to={{ pathname: '/auth/login', state: { from: props.location } }}
            />
          );
        } else if (!isAuthProtected && user && AUTH_ROUTES.includes(path)) {
          return (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          );
        }
        return <Component {...props} />;
      }}
    />
  );
};

export default Authmiddleware;
