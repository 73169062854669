import cn from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import './index.scss';

interface SpinnerProps {
  className?: string;
}

export const Spinner = ({ className }: SpinnerProps): JSX.Element => {
  return (
    <div
      className={cn(
        'flex justify-center items-center text-yellow h-28',
        className
      )}
    >
      <CircularProgress />
    </div>
  );
};
