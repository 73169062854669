import { useContext } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { UserContext } from 'contexts/UserContext';
import { Avatar } from 'components';

import './index.scss';

export const Navbar = ({ variant = 'sidebar' }) => {
  const { user } = useContext(UserContext);
  return (
    <>
      {user ? (
        <header
          className={cn('h-23 w-full fixed bg-gray-50 z-10', {
            'pl-64': variant === 'sidebar'
          })}
        >
          <div className="pl-20 pr-32 h-full flex justify-between items-center">
            <Avatar linkTo="/" user={user} />
          </div>
        </header>
      ) : null}
    </>
  );
};

Navbar.propTypes = {
  variant: PropTypes.oneOf(['noSidebar', 'sidebar'])
};
