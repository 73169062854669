import React from 'react';
import cn from 'classnames';
import { Icon } from 'components';
import { SvgIcons } from 'components/Icon/index';
import CurrencyFormat from 'react-currency-format';

interface FeaturedDataProps {
  icon: keyof typeof SvgIcons;
  iconWidth?: number;
  iconHeight?: number;
  number: string;
  text: string;
  loading?: boolean;
  className?: string;
}

export const FeaturedData = ({
  icon,
  iconWidth,
  iconHeight,
  number,
  text,
  loading,
  className
}: FeaturedDataProps) => {
  return (
    <div
      className={cn(
        'box flex items-start flex-col lg:flex-row lg:items-center bg-white rounded-md shadow-md',
        className,
        {
          'opacity-30': loading
        }
      )}
    >
      <Icon
        name={icon}
        rounded
        center
        width={iconWidth}
        height={iconHeight}
        canvasWidth={45}
        canvasHeight={45}
        className={cn('bg-yellow-light shadow-none', { 'opacity-0': loading })}
      />

      <div
        className={cn('text-gray-dark mt-4 lg:pl-4 lg:mt-0', {
          'opacity-0': loading
        })}
      >
        <strong className="font-semibold text-xxl block tracking-tighter leading-7">
          <CurrencyFormat
            value={number}
            displayType={'text'}
            thousandSeparator={'.'}
            prefix={''}
            decimalSeparator={','}
          />
        </strong>
        <span className="text-2xs whitespace-nowrap block">{text}</span>
      </div>
    </div>
  );
};
