/* eslint-disable eqeqeq */
export const getPaginationArray = (
  currentPage: number,
  totalPages: number,
  delta: number
) => {
  let current = currentPage,
    last = totalPages,
    left = current - delta,
    right = current + delta + 1,
    range = [],
    rangeWithDots: string[] = [],
    l;

  for (let i = 1; i <= last; i++) {
    if (i == 1 || i == last || (i >= left && i < right)) {
      range.push(i);
    }
  }

  for (let i of range) {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push((l + 1).toString());
      } else if (i - l !== 1) {
        rangeWithDots.push('...');
      }
    }
    rangeWithDots.push(i.toString());
    l = i;
  }

  return rangeWithDots.map((p: string) => (p !== '...' ? parseInt(p) : p));
};
