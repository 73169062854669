import React from 'react';

import background from 'assets/img/auth/background.png';
import tcc from 'assets/img/auth/tmc.png';

import './index.scss';

export const SideImage = () => {
  return (
    <div
      style={{ backgroundImage: `url('${background}')` }}
      className="auth-side-image"
    >
      <div className="absolute top-0 right-14">
        <img
          src={tcc}
          alt="The Machine Club"
          className="h-screen mix-blend-soft-light opacity-7"
        />
      </div>
    </div>
  );
};
