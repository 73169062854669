import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getPublicationStatus } from 'utils/formatter';
import './index.scss';

interface PublicationProps {
  isActive?: boolean;
  isVerified?: boolean;
  verifiedDate?: Date;
}

export const PublicationBadge = ({
  isActive,
  isVerified,
  verifiedDate
}: PublicationProps): JSX.Element => {
  const { t } = useTranslation();
  const colors: Record<string, string> = {
    active: 'green',
    rejected: 'red',
    review: 'yellow',
    inactive: 'gray'
  };

  const label: string = useMemo(
    () => getPublicationStatus(isActive, isVerified, verifiedDate),
    [isActive, isVerified, verifiedDate]
  );

  return (
    <div className={`cb-badge ${colors[label]}`}> {t(`badges.${label}`)}</div>
  );
};
