import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  fromUSTonsToTons,
  fromTonsToUSTons,
  fromMeterToFeet,
  fromFeetToMeter
} from 'utils/formatter';
import cn from 'classnames';
import './index.scss';

const fieldsByUnits: any = {
  maxLoadCapacity: 'capacity',
  liftingCapacityAtMaxRadius: 'capacity',
  maxRadio: 'length',
  feather: 'length',
  span: 'length',
  hoistingLoweringMax: 'valocity'
};

const fieldOrder = [
  'year',
  'condition',
  'maxLoadCapacity',
  'horometer',
  'feather',
  'maxRadio',
  'liftingCapacityAtMaxRadius',
  'jib',
  'winches',
  'hooks',
  'maxHookHeight',
  'luffingJib',
  'superLift',
  'span',
  'hoistingLoweringMax'
];

export const PublicationConfigurationTable = ({
  configuration,
  unit = 'metric'
}: {
  configuration: any;
  unit?: string;
}) => {
  const [selectedUnit, setSelectedUnit] = useState<string>(unit || 'metric');
  const { t } = useTranslation();

  const getValue = (value: number, key: string) => {
    if (typeof value === 'boolean') {
      return value ? t('yes') : t('no');
    } else if (fieldsByUnits[key] && unit !== selectedUnit) {
      // eslint-disable-next-line default-case
      switch (fieldsByUnits[key]) {
        case 'capacity':
          if (selectedUnit === 'metric') return fromUSTonsToTons(value);
          else return fromTonsToUSTons(value);
        case 'length':
          if (selectedUnit === 'metric') return fromFeetToMeter(value);
          else return fromMeterToFeet(value);
        case 'valocity':
          if (selectedUnit === 'metric') return fromFeetToMeter(value);
          else return fromMeterToFeet(value);
      }
    }
    return value;
  };

  return (
    <div className="py-6">
      <div className="flex text-xs mb-3 justify-end">
        <span
          className={cn(
            selectedUnit === 'metric'
              ? 'underline text-yellow pointer-events-none'
              : 'cursor-pointer text-silver'
          )}
          onClick={() => setSelectedUnit('metric')}
        >
          {t('metric')}
        </span>
        <span className="text-silver"> / </span>
        <span
          className={cn(
            selectedUnit === 'imperial'
              ? 'underline text-yellow pointer-events-none'
              : 'cursor-pointer text-silver'
          )}
          onClick={() => setSelectedUnit('imperial')}
        >
          {t('imperial')}
        </span>
      </div>
      <div className="cb-config-table  flex flex-wrap">
        {configuration &&
          fieldOrder.map(
            (k, i) =>
              configuration[k] && (
                <div
                  key={i}
                  className="w-full md:w-1/2 config-table-cell text-sm py-1 px-5 flex flex-wrap"
                >
                  <strong className="whitespace-nowrap">{t(k)}</strong>:
                  <span>&nbsp;</span>
                  <div className="whitespace-nowrap">
                    <span className="capitalize">
                      {getValue(configuration[k], k)}
                    </span>
                    {fieldsByUnits[k] && (
                      <>
                        {' '}
                        <span>
                          {t(
                            `unitAbbreviations.${fieldsByUnits[k]}.${selectedUnit}`
                          )}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              )
          )}
      </div>
    </div>
  );
};
