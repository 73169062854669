import React, { useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from 'assets/img/marker.png';

interface MapProps {
  center?: boolean;
  zoom?: number;
  className?: string;
  markers?: any;
  defaultCenter?: any;
}

export const Map = ({
  center,
  zoom = 0,
  className,
  markers,
  defaultCenter
}: MapProps) => {
  useEffect(() => {}, [center, markers]);
  return (
    <div className={className} style={{ width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_MAPS }}
        defaultCenter={
          defaultCenter ||
          (markers && markers.length
            ? markers[0].position
            : {
                lat: 11.195506582525123,
                lng: -16.12313805569712
              })
        }
        center={center}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }: any) => console.log('Loading...')}
        defaultZoom={zoom}
        zoom={markers && markers[0] ? 15 : zoom}
      >
        {markers &&
          markers.map(
            (location: Location, index: any) =>
              location && (
                <img
                  key={index}
                  className="h-10 w-10"
                  // lat={location?.position?.lat}
                  // lng={location?.position?.lng}
                  src={Marker}
                  alt="Marker"
                />
              )
          )}
      </GoogleMapReact>
    </div>
  );
};
