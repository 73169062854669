import React from 'react';
import { SideImage } from 'components';

import './index.scss';

export interface AuthLayoutProps {
  children?: React.ReactNode;
}

export const AuthLayout: React.FC = ({ children }: AuthLayoutProps) => {
  return (
    <>
      <SideImage />
      <main className="auth-main">
        <div>{children}</div>
      </main>
    </>
  );
};
