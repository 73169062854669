// Auth routes
import Login from 'pages/auth/Login';

// Admin routes
import Home from 'pages/index';
import Publications from 'pages/Publications';
import Companies from 'pages/Companies';
import Posts from 'pages/Posts';
import Quotations from 'pages/Quotations';
import Users from 'pages/Users';

interface Route {
  path: string;
  component: React.ReactNode;
  exact?: boolean;
}

export const adminRoutes: Array<Route> = [
  { path: '/', component: Home, exact: true },
  { path: '/publications', component: Publications, exact: true },
  { path: '/companies', component: Companies, exact: true },
  { path: '/users', component: Users, exact: true },
  { path: '/posts', component: Posts, exact: true },
  { path: '/quotations', component: Quotations, exact: true }
];

export const authRoutes: Array<Route> = [
  { path: '/auth/login', component: Login, exact: true }
];
