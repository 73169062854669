import axios from 'utils/axios';

export const getPublications = async (
  page: number = 0,
  size: number = 10,
  state: string = ''
) => {
  try {
    const { data } = await axios.get(
      `api/publications?page=${page}&size=${size}&state=${state}`
    );
    return data;
  } catch (err) {
    console.log(err);
    return { count: 0, rows: [], totalPages: 0 };
  }
};

export const getPublication = async (id: number) => {
  try {
    const { data } = await axios.get(`api/publications/${id}`);
    return data;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const reviewPublication = async (review: PublicationReview) => {
  try {
    await axios.post('api/review', review);
  } catch (err) {
    console.log(err);
  }
};
