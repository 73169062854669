import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';

interface CompanyBadgeProps {
  isActive?: boolean;
  isVerified?: boolean;
  verifiedDate?: Date;
}

export const CompanyBadge = ({
  isVerified
}: CompanyBadgeProps): JSX.Element => {
  const { t } = useTranslation();
  const colors: Record<string, string> = {
    verified: 'green',
    notVerified: 'gray'
  };

  const label: string = useMemo(
    () => (isVerified ? 'verified' : 'notVerified'),

    [isVerified]
  );

  return (
    <div className={`cb-badge ${colors[label]}`}> {t(`badges.${label}`)}</div>
  );
};
