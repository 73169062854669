import { authIntance } from 'utils/axios';

export const login = async ({
  email,
  password
}: {
  email: string;
  password: string;
}): Promise<User> => {
  try {
    const res = await authIntance.post('api/auth/login-admin', {
      email,
      password
    });
    localStorage.setItem('tcc-token-ad', res.data.token);
    return res.data.user;
  } catch (error: any) {
    throw error?.response?.data || error.message;
  }
};

export const logout = async () => {
  try {
    await authIntance.get('api/auth/logout');
    localStorage.removeItem('tcc-token-ad');
  } catch (error) {
    console.log(error);
    throw error;
  }
};
