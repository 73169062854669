import { useEffect, useState } from 'react';
import { getPublication } from 'api/publications';
import { useTranslation } from 'react-i18next';
import { Spinner, CurrencyFormat } from 'components';
import cn from 'classnames';
import Tooltip from '@mui/material/Tooltip';
import './index.scss';

import {
  PublicationMedia,
  Divider,
  PublicationDescription,
  PublicationTitle,
  PublicationLocation,
  PublicationConfigurationTable,
  PublicationCertified,
  PublicationCompany,
  PublicationFiles,
  PublicationMap,
  Button,
  Icon
} from 'components';

interface PublicationToReviewProps {
  id: number;
  onClose: () => void;
  onApprove: () => void;
  onReject: () => void;
}

export const PublicationToReview = ({
  id,
  onClose,
  onApprove,
  onReject
}: PublicationToReviewProps) => {
  const [publication, setPublication] = useState<Publication>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [valid, setValid] = useState<boolean>(false);
  const [images, setImages] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    loadPublication(id);
  }, [id]);

  const loadPublication = async (id: number) => {
    try {
      setLoading(true);
      const { publication } = await getPublication(id);
      setImages(
        publication.publicationFiles.filter(
          (a: PublicationFile) => a.type === 'resized'
        )
      );
      setPublication(publication);
      setValid(true);
    } catch (error) {
      setValid(false);
      console.log('Publication error', error);
    } finally {
      setLoading(false);
    }
  };

  if (!valid && !loading) {
    return (
      <div className="flex items-center justify-center text-2xl text-yellow p-8">
        <p>{t('publicationNotFound')}</p>
      </div>
    );
  }

  return loading ? (
    <div className="flex h-screen">
      <Spinner className="m-auto" />
    </div>
  ) : (
    <div>
      {publication && !loading && (
        <>
          <div className="fixed top-0 left-0 w-full z-50 bg-white shadow-md">
            <div className="wrapper-lg">
              <div className="flex items-center justify-end rounded-lg p-3">
                <Button onClick={onApprove} className="w-36">
                  {t('approve')}
                </Button>
                <Button
                  variant="outline"
                  onClick={onReject}
                  className="w-36 mx-5"
                >
                  {t('refuse')}
                </Button>

                <Icon name="Close" width={44} height={44} onClick={onClose} />
              </div>
            </div>
          </div>+

          <div className="wrapper-lg pt-23">
            <div
              className={cn('flex pt-7 items-start', {
                'animation-pulse pointer-events-none': !publication
              })}
            >
              <div className="w-7/12 pr-7">
                <PublicationMedia
                  files={images}
                  videoURL={publication?.videoURL}
                />

                <Divider color="dark" className="mt-10" />

                <PublicationDescription
                  description={publication?.description!}
                />

                <Divider color="dark" className="mb-10" />
              </div>

              <div className="md:w-5/12 bg-gray-light rounded-lg py-5 px-4 md:py-6 md:px-10 md:pb-12 sticky top-0">
                <PublicationTitle publication={publication} />

                <Divider color="dark" />

                <div className="flex items-center justify-between flex-wrap">
                  <div className="my-7">
                    <PublicationLocation publication={publication} />
                  </div>
                </div>

                <div className="my-3">
                  {/* @ts-ignore */}
                  {!isNaN(parseInt(publication?.EXWPrice)) ? (
                    <div
                      className={cn(
                        'flex items-center text-2xl md:text-2xxl tracking-tighter last:mb-0 text-gray-light pb-5'
                      )}
                    >
                      {/* @ts-ignore */}
                      {publication?.EXWPrice > 0 ? (
                        <>
                          <span className="text-sm md:text-lg tracking-normal mr-1 md:mr-2 text-gray">
                            {' '}
                            {/* @ts-ignore */}
                            {t(publication.currency)}{' '}
                          </span>
                          <CurrencyFormat
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            // @ts-ignore
                            value={publication?.EXWPrice}
                            className="w-max mr-4 text-white-secondary"
                          />
                        </>
                      ) : (
                        <span className="capitalize text-yellow text-xl font-semibold mr-3">
                          {t('inquire')}
                        </span>
                      )}
                      <div className="chip sell">{t('sell')}</div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {!!publication?.nationalRentPrice && (
                    <div
                      className={`flex items-center text-2xl md:text-2xxl tracking-tighter mb-3 md:mb-5 last:mb-0 text-gray-dark ${
                        publication.internationalRentPrice ? '' : 'pb-5'
                      }`}
                    >
                      <span className="text-sm md:text-lg tracking-normal mr-1 md:mr-2 text-gray-500">
                        {t(publication?.currency || 'dollar')}
                      </span>
                      <CurrencyFormat
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        // @ts-ignore
                        value={publication?.nationalRentPrice}
                        className="w-max md:mr-0"
                      />
                      <span className="text-xs ml-2 text-gray-500 uppercase tracking-normal">
                        /{t('month')}
                      </span>

                      <Tooltip
                        title={t('withOperator') || ''}
                        placement="right"
                        disableHoverListener={
                          !publication?.nationalRentOperator
                        }
                      >
                        <div className="chip rent">
                          {t('nationalRent')}

                          {publication?.nationalRentOperator && (
                            <Icon
                              name="Operator"
                              width={10}
                              height={11}
                              canvasWidth={22}
                              canvasHeight={22}
                              className="bg-blue"
                              rounded
                              center
                            />
                          )}
                        </div>
                      </Tooltip>
                    </div>
                  )}

                  {!!publication?.internationalRentPrice && (
                    <div className="flex items-center text-2xl md:text-2xxl tracking-tighter mb-3 md:mb-5 last:mb-0 text-gray-dark pb-5">
                      <span className="text-sm md:text-lg tracking-normal mr-1 md:mr-2 text-gray-500">
                        {t(publication?.currency || 'dollar')}
                      </span>
                      <CurrencyFormat
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        // @ts-ignore
                        value={publication?.internationalRentPrice}
                        className="w-max md:mr-0"
                      />
                      <span className="text-xs ml-2 text-gray-500 uppercase tracking-normal">
                        /{t('month')}
                      </span>

                      <Tooltip
                        title={t('withOperator') || ''}
                        placement="right"
                        disableHoverListener={
                          !publication?.internationalRentOperator
                        }
                      >
                        <div className="chip rent">
                          {t('internationalRent')}

                          {publication?.internationalRentOperator && (
                            <Icon
                              name="Operator"
                              className="bg-blue"
                              width={10}
                              height={11}
                              canvasWidth={22}
                              canvasHeight={22}
                              rounded
                              center
                            />
                          )}
                        </div>
                      </Tooltip>
                    </div>
                  )}
                </div>

                <Divider color="dark" className="" />

                <PublicationConfigurationTable
                  unit={publication?.unit}
                  configuration={{
                    year: publication?.year,
                    condition: publication?.condition,
                    ...publication?.configuration
                  }}
                />

                {publication?.isCertificated && (
                  <>
                    <PublicationCertified publication={publication} />
                    <Divider color="dark" className="mt-6 mb-6" />
                  </>
                )}

                <div className="mt-6">
                  <PublicationCompany publication={publication} />
                </div>
              </div>
            </div>

            <div className="w-7/12 pr-7 mb-14">
              <PublicationFiles publication={publication} />
            </div>

            {publication?.location && (
              <PublicationMap publication={publication} />
            )}
          </div>
        </>
      )}
    </div>
  );
};
