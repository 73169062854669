import { useQueryParams, NumberParam } from 'use-query-params';

export const usePageQueryParams = () => {
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    orderBy: NumberParam
  });

  const setPage = (value: number) => {
    setQuery({ ...query, page: value });
  };

  const removePage = () => {
    setQuery({ ...query, page: undefined });
  };

  const getPage = () => query?.page || 1;

  const setOrderBy = (value: number) => {
    setQuery({ ...query, orderBy: value });
  };

  const removeOrderBy = () => {
    setQuery({ ...query, orderBy: undefined });
  };

  const getOrderBy = () => query?.orderBy || 0;

  return {
    getPage,
    setPage,
    removePage,
    setOrderBy,
    removeOrderBy,
    getOrderBy
  };
};

export default usePageQueryParams;
