import axios from 'utils/axios';

export const getDashboardData = async () => {
  try {
    const { data } = await axios.get('/api/dashboard');
    return data;
  } catch (err) {
    console.log(err);
  }
};
