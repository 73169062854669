import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils/formatter';

export const PublicationTitle = ({
  publication
}: {
  publication: Publication;
}) => {
  const { t } = useTranslation();
  return (
    <h1 className="text-xl md:text-xxl uppercase pb-5">
      <span className="block text-xs text-gray-500 leading-5 tracking-normal">
        {publication?.category?.name}
      </span>
      <strong>{publication?.customBrand || publication?.brand?.name} </strong>
      {publication?.model}
      <span className="block text-2xs text-gray-500 leading-5 tracking-normal capitalize">
        {t('published')} {formatDate(publication?.createdAt)}
      </span>
    </h1>
  );
};
