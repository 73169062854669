import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { login } from 'api/auth';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UserContext } from 'contexts/UserContext';
import { Button } from '@estudio-nk/the-crane-club-client-lib'
import { Logo, Icon, Input } from 'components';

const Login = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const { setUser } = useContext(UserContext);
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  const handleOnSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await login({ email, password });
      setUser(res);
    } catch (error: any) {
      toast.error(t(error.data));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="App overflow-visible">
      <Logo
        size="big"
        className="w-48 mb-10 cursor-pointer"
        onClick={() => history.push('/')}
      />

      <h2 className="text-xl font-bold mb-8">{t('welcomeBack')}</h2>

      <form>
        <div className="mb-5">
          <Input placeholder={t('email')} type="email" onChange={setEmail} />
        </div>
        <div className="input-with-icon mb-11">
          <Input
            placeholder={t('password')}
            type={showPassword ? 'text' : 'password'}
            onChange={setPassword}
          />
          {showPassword ? (
            <Icon
              name="ShowPassword"
              width="19"
              height="13"
              onClick={() => setShowPassword(false)}
            />
          ) : (
            <Icon
              name="HidePassword"
              width="20"
              height="17"
              onClick={() => setShowPassword(true)}
            />
          )}
        </div>
        <Button type="submit" onClick={handleOnSubmit} colorVariant="yellow" loading={loading}>
          {t('Login')}
        </Button>
      </form>
    </div>
  );
};

export default Login;
