import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

import { AuthRoutes, AdminRoutes } from '../AppRoutes';

//Overview routes is the route for default.
const Routes = () => {
  return (
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        <Switch>
          <Route path="/:path?" exact>
            <AdminRoutes />
          </Route>
          <Route path="/auth/:path?" exact>
            <AuthRoutes />
          </Route>
        </Switch>
      </QueryParamProvider>
    </Router>
  );
};

export default Routes;
