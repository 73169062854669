import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components';


export const PublicationCertified = ({ publication }: { publication: Publication }) => {
  const { t } = useTranslation();
  
  return (
    <div className="cb-publication-certified text-green bg-green-panache border border-solid border-green flex items-center justify-between text-sm pt-4 pb-4 pr-4 pl-9 rounded-lg">
      <div>
        <span className="font-bold uppercase block">{t('equipoCertificado')}</span>
        <span>{t('validity')}: {publication?.certificateValidityFrom} - {publication?.certificateValidityTo}</span>
      </div>
      <Link to={publication?.cerficateFile!} target="_blank" download className="flex items-center cursor-pointer">
        <span className="underline text-sm mr-4">{t('viewCertificate')}</span>
        <Icon name="Certificate" width={16} height={20} canvasWidth={50} canvasHeight={50} rounded={true} center={true}/>
      </Link>
    </div>
  );
};