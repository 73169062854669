import { forwardRef } from 'react';
import CInput from '@material-ui/core/Input';
import { useTranslation } from '../../../node_modules/react-i18next';
import cn from 'classnames';

import './index.scss';

interface Error {
  type: string;
}

interface InputProps {
  type?: string;
  onChange?: (value: string) => void;
  className?: string;
  placeholder?: string;
  secondary?: boolean;
  register?: () => {};
  errorMessage?: string;
  error?: Error;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type = 'text',
      onChange,
      className,
      secondary,
      register,
      errorMessage,
      error,
      ...rest
    }: InputProps,
    ref
  ) => {
    const { t } = useTranslation();
    return (
      <div className={cn(className)}>
        {secondary ? (
          <input
            className={cn('cb-input', { error })}
            type={type}
            ref={ref}
            onChange={(e) => {
              onChange && onChange(e.target.value);
            }}
            {...rest}
          />
        ) : (
          <CInput
            className={cn({ error })}
            type={type}
            {...register}
            ref={ref}
            onChange={(e) => {
              onChange && onChange(e.target.value);
            }}
            {...rest}
          />
        )}
        {error && (
          <span className="text-2xs text-yellow-dark flex justify-end mt-1">
            {errorMessage ? errorMessage : t(`form.${error.type}`)}
          </span>
        )}
      </div>
    );
  }
);
