import React, { createContext, useEffect, useState } from 'react';
import { getMe } from 'api/user';

export const UserContext = createContext<any>(null);

export default function Provider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<any>(undefined);

  useEffect(() => {
    loadUser();
  }, []);

  const loadUser = async () => {
    try {
      const res = await getMe();
      setUser(res);
    } catch (err) {
      setUser(null);
    }
  };

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
}
