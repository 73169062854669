import { Icon } from 'components';
import { useTranslation } from 'react-i18next';

export const PublicationCompany = ({
  publication
}: {
  publication: Publication;
}) => {
  const { t } = useTranslation();

  const handleClickWebsite = async (web = '') => {
    if (web) {
      window.open(web, '_blank');
    }
  };

  return (
    <>
      <div className="flex items-center mb-2">
        {publication?.company?.logoURL && (
          <div
            className="w-12 h-12 rounded-xl mr-4 overflow-hidden bg-center bg-no-repeat shadow-md bg-white"
            style={{
              backgroundImage: `url('${publication?.company?.logoURL}')`,
              backgroundSize: '60%'
            }}
          ></div>
        )}
        <div className="flex items-center">
          {/* <span className="uppercase text-lg text-black">{publication?.companyName}</span> */}
          <span className="uppercase text-lg text-black font-bold mr-4">
            {publication?.company?.name}
          </span>
        </div>
      </div>

      {publication?.company?.description && (
        <div className="md:pl-16 pb-5">
          <p className="text-sm md:text-sm text-silver line-clamp-5 leading-normal">
            {publication?.company?.description}
          </p>
        </div>
      )}
      <div className="md:pl-16 flex items-center">
        <Icon
          name="User"
          width={17}
          height={17}
          canvasWidth={19}
          center={true}
          className="mr-3"
        />
        <span>
          {publication?.contactResponsable}
        </span>
      </div>
      <div className="md:pl-16">
        <a
          href={`tel:${publication?.contactPhone}`}
          className="flex items-center my-4 group"
          target="_blank"
          rel="noreferrer"
        >
          <Icon
            name="Smartphone"
            width={11}
            height={19}
            canvasWidth={19}
            center={true}
            className="mr-3"
          />
          <span className="text-sm text-yellow underline group-hover:no-underline">
            {publication?.contactPhone}
          </span>
        </a>

        <a
          href={`mailto:${publication?.contactEmail}`}
          className="flex items-center group"
          target="_blank"
          rel="noreferrer"
        >
          <Icon
            name="Email"
            width={17}
            height={17}
            canvasWidth={19}
            center={true}
            className="mr-3"
          />
          <span className="text-sm text-yellow underline group-hover:no-underline">
            {publication?.contactEmail}
          </span>
        </a>
        <a
          onClick={() =>
            handleClickWebsite(publication?.user?.websiteURL)
          }
          href={`${publication?.user?.websiteURL}?utm_source=TheCraneClub`}
          className="flex items-center group my-4"
          target="_blank"
          rel="noreferrer"
        >
          <Icon
            name="World"
            width={17}
            height={17}
            canvasWidth={19}
            center={true}
            className="mr-3"
          />
          <span className="text-sm text-yellow underline group-hover:no-underline">
            {publication?.user?.websiteURL}
          </span>
        </a>
        <div className="flex items-center company-social justify-center lg:justify-start pl-7 pt-2 lg:pl-0">
          {publication?.user?.facebookURL && (
            <a
              onClick={() =>
                handleClickWebsite(publication?.user?.facebookURL)
              }
              href={`${publication?.user?.facebookURL}?utm_source=TheCraneClub`}
              className="flex items-center group my-4"
              target="_blank"
              rel="noreferrer"
            >
              <Icon
                name="Facebookyellow"
                width={17}
                height={17}
                canvasWidth={19}
                center={true}
                className="mr-3"
              />
            </a>
          )}
          {publication?.user?.instagramURL && (
            <a
              onClick={() =>
                handleClickWebsite(publication?.user?.instagramURL)
              }
              href={`${publication?.user?.instagramURL}?utm_source=TheCraneClub`}
              className="flex items-center group my-4"
              target="_blank"
              rel="noreferrer"
            >
              <Icon
                name="Instagram"
                width={17}
                height={17}
                canvasWidth={19}
                center={true}
                className="mr-3"
              />
            </a>
          )}
          {publication?.user?.linkedinURL && (
            <a
              onClick={() =>
                handleClickWebsite(publication?.user?.linkedinURL)
              }
              href={`${publication?.user?.linkedinURL}?utm_source=TheCraneClub`}
              className="flex items-center group my-4"
              target="_blank"
              rel="noreferrer"
            >
              <Icon
                name="Linkedin"
                width={17}
                height={17}
                canvasWidth={19}
                center={true}
                className="mr-3"
              />
            </a>
          )}
          {publication?.user?.twitterURL && (
            <a
              onClick={() =>
                handleClickWebsite(publication?.user?.twitterURL)
              }
              href={`${publication?.user?.twitterURL}?utm_source=TheCraneClub`}
              className="flex items-center group my-4"
              target="_blank"
              rel="noreferrer"
            >
              <Icon
                name="Twitter"
                width={17}
                height={17}
                canvasWidth={19}
                center={true}
                className="mr-3"
              />
            </a>
          )}
        </div>
      </div>
    </>
  );
};
