import { useEffect, useState, useCallback } from 'react';
import cn from 'classnames';
import { getPosts, deletePost } from 'api/posts';
import { useTranslation } from 'react-i18next';
import { usePageQueryParams } from 'hooks';
import { Table, Spinner, Menu, Icon } from 'components';
import { MenuType } from 'components/Menu';
import { toast } from 'react-toastify';
import { formatDate, postToURL } from '../../utils/formatter';

const Posts = () => {
  const { t } = useTranslation();
  const [posts, setPosts] = useState<User[]>([]);
  const [selected, setSelected] = useState<number | null>(null);
  const { getPage, setPage } = usePageQueryParams();
  const [resultsTotal, setResultsTotal] = useState({
    count: 0,
    totalPages: 0
  });
  const [loading, setLoading] = useState<boolean>(true);
  const page = getPage() || 1;

  useEffect(() => {
    setLoading(true);
    load();
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const load = async () => {
    try {
      const { count, rows, totalPages } = await getPosts(page - 1, 12);
      setResultsTotal({ count, totalPages });
      setPosts(rows);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleOnDelete = async (postId: number) => {
    try {
      setSelected(postId);
      await deletePost(postId);
      toast.success(t('notifications.postDeleted'));
      load();
    } catch (error) {
      console.log(error);
      toast.error(t('notifications.genericError'));
    } finally {
      setSelected(null);
    }
  };

  const renderMenu = useCallback(
    ({ id }: Post) => {
      const menu: MenuType[] = [
        {
          label: t('delete'),
          icon: 'Trash',
          onClick: () => handleOnDelete(id!)
        }
      ];
      return menu;
    },
    [t, posts]
  );

  const renderRows = () => {
    return posts.map(
      (post: any): JSX.Element => (
        <tr
          key={post.id}
          className="hover:bg-yellow-light transition duration-300"
        >
          <Table.Cell className="cursor-pointer" onClick={() => null}>
            <a href={postToURL(post.id)} target="_blank" rel="noreferrer">
              <div className="flex items-center">
                <div
                  className="flex-shrink-0 h-10 w-12 bg-no-repeat bg-cover rounded-md bg-center"
                  style={{
                    backgroundImage: `url('${post?.postFiles[0]?.url}')`
                  }}
                />
                <div className="ml-4">
                  <div className="text-sm font-medium text-gray-900 max-w-xl">
                    <p className="text-ellipsis overflow-hidden">
                      {post.description}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </Table.Cell>
          <Table.Cell>
            {post.user?.name ? post.user?.name : post.user?.email}
          </Table.Cell>
          <Table.Cell>{formatDate(post.createdAt)}</Table.Cell>
          <Table.Cell>
            <Menu
              trigger={
                <Icon
                  name="VerticalDots"
                  width="16"
                  height="16"
                  canvasWidth={34}
                  canvasHeight={34}
                  rounded
                  center
                  loading={selected === post.id}
                  onClick={() => null}
                />
              }
              items={renderMenu(post)}
            />
          </Table.Cell>
        </tr>
      )
    );
  };

  return (
    <div
      className={cn('py-5 px-10', {
        'animate-pulse pointer-events-none': loading
      })}
    >
      <h3 className="text-2xl">{t('posts')}</h3>
      <div className="flex items-center justify-between h-8 mb-5">
        <div
          className={cn('text-silver text-xs', {
            'bg-silver-light rounded-sm text-transparent w-24 h-4': loading
          })}
        >
          {!loading && (
            <span className="text-gray">
              {t('totalItems', { value: resultsTotal.count })}
            </span>
          )}
        </div>
      </div>

      <div className={`grid grid-cols-1 gap-6`}>
        {loading ? (
          <Spinner />
        ) : (
          <Table
            page={page}
            setPage={setPage}
            totalPages={resultsTotal.totalPages}
            headerLabels={[t('description'), t('user'), t('created'), '']}
            rows={renderRows()}
          ></Table>
        )}
      </div>
    </div>
  );
};

export default Posts;
