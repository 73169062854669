import axios from 'utils/axios';

export const getCompanies = async (page: number = 0, size: number = 10) => {
  try {
    const { data } = await axios.get(`api/companies?page=${page}&size=${size}`);
    return data;
  } catch (err) {
    console.log(err);
    return { count: 0, rows: [], totalPages: 0 };
  }
};

export const verifyCompany = async (companyId: number, isVerified: boolean) => {
  try {
    await axios.put(`api/companies/${companyId}`, {
      isVerified
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteCompany = async (companyId: number) => {
  try {
    await axios.delete(`api/companies/${companyId}`);
  } catch (err) {
    console.log(err);
  }
};

export const toggleVIP = async (companyId: number, isVIP: boolean) => {
  try {
    await axios.put(`api/companies/toggle-vip/${companyId}`, {
      isVIP
    });
  } catch (err) {
    console.log(err);
  }
};
