import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { I18nextProvider } from '../node_modules/react-i18next';
import UserProvider from 'contexts/UserContext';
import i18n from './i18n';

ReactDOM.render(
  <React.StrictMode>
    <UserProvider>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </UserProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
