import React from 'react';
import ReactDOM from 'react-dom';
import { Icon, Divider, Button } from 'components';
import { useTranslation } from 'react-i18next';

export interface ButtonI {
  onClick: (p?: any) => void | string | Promise<void>;
  text: string;
  fullWidth?: boolean;
  [x: string]: any;
}

export interface ModalProps {
  visible: boolean;
  toggle: () => void;
  closeOnSubmit?: boolean;
  title?: string;
  buttons?: ButtonI[];
  children?: React.ReactNode;
}

export const Modal = ({
  visible,
  toggle,
  title,
  buttons,
  closeOnSubmit = true,
  children
}: ModalProps) => {
  const { t } = useTranslation();

  const handleButtonClick = (
    onClick: (p?: any) => void | string | Promise<void>
  ): void => {
    closeOnSubmit && toggle();
    onClick && onClick();
  };

  return visible
    ? ReactDOM.createPortal(
        <div className="fixed top-0 left-0 w-full h-screen z-50">
          <div
            className="modal-content absolute top-2/4 left-0 right-0 m-auto bg-white rounded-lg pt-7 max-w-full w-min z-10 max-h-screen overflow-auto"
            style={{ transform: 'translateY(-50%)' }}
            role="dialog"
            aria-modal="true"
          >
            <div className="flex items-center justify-between px-11 pb-7">
              <span className="text-2xl text-black font-bold tracking-tight">
                {title}
              </span>
              <Icon
                name="Close"
                width={24}
                height={24}
                onClick={toggle}
                className="transition-all duration-300 transform hover:rotate-90"
              />
            </div>

            {/* <Divider className="mb-10" /> */}

            <div className="px-11">{children}</div>

            {buttons && (
              <>
                <Divider className="mt-10" />

                <div className="flex items-center justify-center py-5 px-11">
                  {buttons?.map((button: ButtonI, index: number) => (
                    <Button
                      {...button}
                      key={index}
                      onClick={() => handleButtonClick(button.onClick)}
                      className={`px-11 mx-1 ${
                        button.fullWidth ? 'w-full' : 'w-max'
                      }`}
                    >
                      {t(button.text)}
                    </Button>
                  ))}
                </div>
              </>
            )}
          </div>

          <div
            className="modal-overlay absolute top-0 left-0 w-full h-full backdrop-filter backdrop-blur-sm bg-black bg-opacity-20"
            onClick={toggle}
          ></div>
        </div>,

        document.body
      )
    : null;
};
