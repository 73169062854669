import { useContext } from 'react';
import { Logo, Icon, Divider } from 'components';
import { useLocation, useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { UserContext } from 'contexts/UserContext';
import { useTranslation } from '../../../node_modules/react-i18next';
import { logout } from 'api/auth';
import cn from 'classnames';

import './index.scss';

export const Sidebar = () => {
  const { setUser } = useContext(UserContext);
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const goToHome = () => {
    history.push('');
  };

  const handleOnLogout = async () => {
    try {
      await logout();
      setUser(null);
      history.push('/auth/login');
    } catch (err) {}
  };

  return (
    <aside className="dashboard-sidebar">
      <div>
        <Logo
          size="collapsed"
          className="w-20 h-7 mb-8 cursor-pointer"
          onClick={() => goToHome()}
        />
        <Divider className="mb-20" />

        <>
          <ul>
            <li
              className={cn('dashboard-sidebar-item', {
                'dashboard-sidebar-item-selected': location.pathname === '/'
              })}
            >
              <Link to="/">
                <Icon
                  name="House"
                  width="20"
                  height="16"
                  canvasWidth={22}
                  center
                />
                <span>{t('general')}</span>
              </Link>
            </li>
            <li
              className={cn('dashboard-sidebar-item', {
                'dashboard-sidebar-item-selected':
                  location.pathname === '/publications'
              })}
            >
              <Link to="/publications">
                <Icon
                  name="Machine"
                  width="22"
                  height="15"
                  canvasWidth={22}
                  center
                />
                <span>{t('machines')}</span>
              </Link>
            </li>
            <li
              className={cn('dashboard-sidebar-item', {
                'dashboard-sidebar-item-selected':
                  location.pathname === '/quotations'
              })}
            >
              <Link to="/quotations">
                <Icon
                  name="Pen"
                  width="22"
                  height="15"
                  canvasWidth={22}
                  center
                />
                <span>{t('quotations')}</span>
              </Link>
            </li>
            <li
              className={cn('dashboard-sidebar-item', {
                'dashboard-sidebar-item-selected':
                  location.pathname === '/companies'
              })}
            >
              <Link to="/companies">
                <Icon
                  name="Market"
                  width="22"
                  height="15"
                  canvasWidth={22}
                  center
                />
                <span>{t('companies')}</span>
              </Link>
            </li>
            <li
              className={cn('dashboard-sidebar-item', {
                'dashboard-sidebar-item-selected':
                  location.pathname === '/users'
              })}
            >
              <Link to="/users">
                <Icon
                  name="User"
                  width="22"
                  height="15"
                  canvasWidth={22}
                  center
                />
                <span>{t('users')}</span>
              </Link>
            </li>
            <li
              className={cn('dashboard-sidebar-item', {
                'dashboard-sidebar-item-selected':
                  location.pathname === '/posts'
              })}
            >
              <Link to="/posts">
                <Icon
                  name="Posts"
                  width="22"
                  height="15"
                  canvasWidth={22}
                  center
                />
                <span>{t('posts')}</span>
              </Link>
            </li>
          </ul>
        </>
      </div>
      <div className="mb-12">
        <Divider />
        <div className="logout items-center" onClick={handleOnLogout}>
          <Icon name="Logout" width="21" height="16" canvasWidth={22} />
          <span className="text-gray">{t('logout')}</span>
        </div>
      </div>
    </aside>
  );
};
