import React from 'react';
import cn from 'classnames';

interface BoxProps {
  title?: string;
  buttonText?: string;
  onClick?: () => void;
  children?: React.ReactNode;
  className?: string;
  loading?: boolean;
}

export const Box = ({
  title,
  buttonText,
  onClick,
  children,
  className,
  loading
}: BoxProps) => {
  return (
    <div
      className={cn('box ', className, {
        'opacity-40': loading
      })}
    >
      <div className="flex items-center justify-between mb-6 bg-white">
        <h4
          className={cn('text-sm uppercase font-semibold', {
            'bg-silver-light w-2/4 h-4 rounded-xs text-transparent': loading,
            'text-gray-dark': !loading
          })}
        >
          {title}
        </h4>
        {buttonText && onClick && !loading && (
          <button
            onClick={onClick}
            className="text-xs text-yellow hover:underline"
          >
            {buttonText}
          </button>
        )}
      </div>

      <div className={cn({ 'h-20': loading })}>{!loading && children}</div>
    </div>
  );
};
