import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components';
import { isValidURL } from 'utils/validators';

export const PublicationFiles = ({
  publication
}: {
  publication: Publication;
}) => {
  const { t } = useTranslation();

  return isValidURL(publication?.configuration?.technicalDataLink) ||
    (
      publication?.publicationFiles?.filter(
        (file: PublicationFile) => file?.type === 'relatedFile'
      ) || []
    ).length > 0 ? (
    <div>
      <h4 className="text-xl mb-5 font-weight-300">{t('relatedFiles')}</h4>

      <div className="bg-white rounded-lg p-3 md:p-6">
        {isValidURL(publication?.configuration?.technicalDataLink) && (
          <div className="bg-silver-100 flex items-center justify-between py-3 px-2 md:px-5">
            <div className="flex items-center">
              <Icon name="CertificateYellow" width={17} height={22} />
              <span className="ml-3 md:ml-10 text-sm md:text-base">
                {t('dataSheet')}
              </span>
            </div>

            <a
              href={publication?.configuration?.technicalDataLink}
              target="_blank"
              download
              rel="noreferrer"
              className="text-yellow font-bold text-xs md:text-base hover:underline cursor-pointer"
            >
              {t('download')}
            </a>
          </div>
        )}

        {publication?.publicationFiles?.length
          ? publication?.publicationFiles?.map((file, i) =>
              file.type === 'relatedFile' ? (
                <div
                  className="bg-white odd:bg-silver-100 flex items-center justify-between py-3 px-2 md:px-5"
                  key={i}
                >
                  <div className="flex items-center">
                    <Icon name="CertificateYellow" width={17} height={22} />
                    <span className="ml-3 md:ml-10 text-sm md:text-base">
                      {file.label || `${t('relatedFile')} ${i + 1}`}
                    </span>
                  </div>

                  <a
                    href={file.url}
                    target="_blank"
                    download
                    rel="noreferrer"
                    className="text-yellow font-bold text-xs md:text-base hover:underline cursor-pointer"
                  >
                    {t('download')}
                  </a>
                </div>
              ) : null
            )
          : null}
      </div>
    </div>
  ) : null;
};
