// import { getBrands } from 'api/brands';
// import { getCategories } from 'api/categories';
import './App.scss';
import Routes from 'routes/Routes';
import 'react-phone-input-2/lib/style.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => (
  <div className="App bg-gray-background">
    <Routes />
    <ToastContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar={false}
      closeOnClick
      draggable
      pauseOnHover
    />
  </div>
);

export default App;
